<template>
    <div class='notebook_detail_container' ref='notebook_detail_list'>
        <div class='notebook_detail_main' v-if='pdf'>
            <div class='notebook_detail_block'  v-for="i in numPages"  :key="i">
                <!-- <pdf :src="pdf"     :page="i"  ></pdf> -->
            </div>
            <el-button 
              v-show='loading'
              type="primary"
              v-loading.fullscreen.lock="loading">
            </el-button>
        </div>
        <div class='notebook_detail_grayBg' @click='closeInfo' ></div>
    </div>
</template>
<script>
import BSscrll from 'better-scroll'
// import pdf from 'vue-pdf-embed'
export default {
    name:'notebook_detail',
    data:()=>{
        return {
            list:[],
            scroll:undefined,
            pdf:null,
            numPages:0,
            loading:false
        }
    },
    created(){
      this.type = this.$route.type || 1;
      this.loadData();
    },
    components:{
        // pdf
    },
    methods:{
      initPdf(url){
        // this.pdf =  pdf.createLoadingTask('http://ioclass.imedunion.com/file/cf5e56cc6af299a5eef5f9a345c95657.pdf'),
        this.pdf =  pdf.createLoadingTask(url)
        this.loading = true;
        this.pdf.promise.then(item => {
          this.numPages = item.numPages || 1;
          // this.loadData();
          setTimeout(()=>{
            this.loading = false;
          },1000)
        }).then((err)=>{
          setTimeout(()=>{
            this.loading = false;
          },1000)
        })
      },
      loadData(){
        let url = this.$tools.getURL(this.$urls.api.pdf,{type:this.type})
        this.$axios.get(url).then((res)=>{
          if(res.errcode) return;
          this.initPdf(res.data[0].url);
        })
        // this.initPdf();
        // this.initScroll()
      },
      closeInfo(){
          this.$emit('closeInfo')
      },
      initScroll(){
          if(!this.scroll){
              this.$nextTick(()=>{
                this.scroll = new BSscrll(this.$refs.notebook_detail_list,{
                    click:true
                })
              }) 
          }else{
              this.scroll.refresh();
          }
      }
    }
}
</script>
<style>
.notebook_detail_container{
    height:100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding:4vw;
}
.notebook_detail_main{
  position: relative;
  overflow: auto;
  z-index:1;
}

.notebook_detail_grayBg{
  height:100%;
  width:100%;
  position: absolute;
  left:0;
  top:0;
  z-index:0;
  /* background:gray; */
  opacity:0.2;
}
.notebook_detail_block{
    border-bottom:4px solid #f1f1f1;
}
.notebook_detail_container button{
    background:#fff;
    border-color:#fff;
}
</style>